import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SubpageHero from "../components/subpage-hero";
import TextImage from "../components/text-image";
import Seo from "../components/seo";
import NewsletterSection from "../components/newsletter-section";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="O fundacji Enjoy The Ride" mdxType="Seo" />
    <SubpageHero image="/illustrations/storm.svg" mdxType="SubpageHero">
      <h1>{`O Fundacji`}</h1>
      <p>{`Wyidealizowane obrazy w social media, wszechobecna presja sukcesu, fałszywe autorytety, chore standardy "piękna"... To wszystko `}<b>{`potrafi przytłoczyć`}</b>{`.`}</p>
      <p>{`Zauważamy, że dorastanie w XXI wieku to wielkie wyzwanie. Może być trudno poznać i zrozumieć siebie. Zbudować zdrowe poczucie własnej wartości, zadbać o swoje granice.`}</p>
      <p>{`W sieci jest sporo miejsc z treściami kierowanymi do Rodziców. "Jak poradzić sobie z nastolatkiem w domu?"`}</p>
      <p>{`Ale my chcemy porozmawiać z samymi nastolatkami. Właśnie z Tobą.`}</p>
    </SubpageHero>
    <section className="etr-container mb-16 lg:mb-40 star-blob">
  <div className="grid lg:grid-cols-7-5 gap-10">
    <div className="max-w-content-lg mx-auto lg:ml-auto lg:mr-0 w-full">
      <h2 className="text-2xl sm:text-3xl font-tiempos mb-6">Początki Fundacji</h2>
      <p className="text-xl sm:text-2xl mb-16">
        Założyciel Fundacji - Maciej Aniserowicz - po latach psychoterapii,
        edukacji i poznawania siebie trafił na wyzwalające słowa piosenki
        Morcheeba “Enjoy The Ride”:
      </p>
      <p className="leading-tight text-2xl sm:text-3xl sm:leading-tight font-tiempos mb-6 text-black">
        With the moonlight to guide you, <br />
        feel the joy of being alive <br />
        The day that you stop running <br />
        Is the day that you arrive <br />
        (...)
      </p>
      <p className="text-4xl leading-tight sm:text-6xl sm:leading-tight font-tiempos mb-6 text-black">
        Stop chasing shadows <br />
        just <u>Enjoy The Ride</u>
      </p>
    </div>
  </div>
    </section>
    <TextImage image="/illustrations/group.svg" alternative mdxType="TextImage">
      <h2>{`Po co powstała Fundacja?`}</h2>
      <p>{`Fundacja Enjoy The Ride powstała, by nastolatkowie nie musieli szukać pomocy tylko w tekstach ulubionych piosenek. Bo szczególnie w pandemicznej rzeczywistości to właśnie im może być naprawdę ciężko.`}</p>
      <p>{`Jest to miejsce, w którym możesz czerpać wiedzę na temat funkcjonowania psychiki i zdrowego rozwoju.`}</p>
    </TextImage>
    <TextImage image="/illustrations/umbrella.svg" mdxType="TextImage">
      <h2>{`Komu chcemy pomóc?`}</h2>
      <p>{`Fundacja odpowiada na alarmujący stan zdrowia psychicznego polskiej młodzieży.`}</p>
      <p>{`Według danych z 2019 roku, w Polsce pracuje jedynie 455 psychiatrów dziecięcych.`}</p>
      <p>{`Dla wielu osób psychoterapia nadal jest luksusem. Albo - co gorsza - tematem tabu. A osoby z najbliższego otoczenia nastolatków nie potrafią przekazywać zdrowych schematów zachowania.`}</p>
    </TextImage>
    <TextImage alternative image="/illustrations/zen.svg" mdxType="TextImage">
      <h2>{`Cel Fundacji`}</h2>
      <p>{`Celem Fundacji jest wskazanie nastolatkom sposobów na dbanie o zdrowie psychiczne, żeby łatwiej Ci było osiągnąć stabilność psychiczną i... "dojrzałość emocjonalną".`}<br />{`
Z tymi umiejętnościami wejście w dorosłe życie będzie o wiele przyjemniejszym procesem.`}</p>
    </TextImage>
    <NewsletterSection mdxType="NewsletterSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      